@font-face {
    font-family: 'Onest';
    src: url('../Fonts/Onest-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Onest';
    src: url('../Fonts/Onest-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Onest';
    src: url('../Fonts/Onest-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Onest';
    src: url('../Fonts/Onest-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Onest';
    src: url('../Fonts/Onest-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Onest';
    src: url('../Fonts/Onest-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Onest';
    src: url('../Fonts/Onest-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}