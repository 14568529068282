.container {
  @apply max-w-[90rem] bg-white w-full block mx-auto;
}

body {
  font-family: Onest;
}
.container-custom {
  max-width: 640px;
  margin: auto;
  padding-bottom: 163px;
}

.form-group input {
  width: 100%;
  height: 55px;
  border-radius: 32px;
  border: 1px solid #8185a0;
  padding-left: 16px;
  margin-bottom: 30px;
}

.form-group {
  position: relative;
}

.invalid-feedback {
  color: red;
  position: absolute;
  bottom: 8px;
  left: 20px;
}
.btn2:hover,
.btn2:active {
  @apply bg-mediumturquoise !text-blacktype;
}
.form_control {
  @apply w-1/2 h-screen pt-[5.625rem] pr-24 pb-[5.813rem] pl-36;
  border-radius: 0px 28px 28px 0px;
}

.form_controler {
  width: 100%;
  height: 55px;
  border-radius: 32px;
  text-align: center;
}
.test-class {
  @apply !bg-blackpearl !text-paleskyblue;
}
.test-class img {
  fill: blue;
}
.pickupheading {
  @apply text-center font-bold text-5xl leading-[3.563rem];
}
.pickupsubheading {
  @apply mt-3 max-w-[40rem] text-xl font-normal leading-[26px] mb-10;
}
body {
  background-color: #fafafa;
}
.cardheading {
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #131313;
}

.buttongroup {
  background: #edeff4;
  border-radius: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #080d23;
  width: 314px;
  padding: 16px 0px;
}

.accountbtn img {
  @apply w-[3.25rem] h-[3.25rem] mb-[3.125rem];
}
.accountbtn h5 {
  @apply font-bold text-[1.25rem] leading-[1.625rem] mb-1 text-left;
}
.accountbtn p {
  @apply max-w-[12rem] font-normal text-[14px] leading-[1.25rem] text-left h-[60px];
}
.btn {
  @apply p-[1.25rem] bg-softpeach  flex flex-col  max-h-[13.375rem];
  border-radius: 24px;
}

.supportbtn {
  @apply bg-softpeach text-blacktype font-normal text-base left-6;
  border-radius: 40px;
  padding: 10px 25px;
}
.pre_activestep {
  @apply !bg-blacktype !text-paleskyblue;
}
.activestep {
  @apply !bg-blacktype !text-paleskyblue relative;
  border: 7px double #fff;
}

/* .activestep::after{
    content: "Delivery";
    position: absolute;
    font-weight: 500;
font-size: 16px;
line-height: 22px;
   @apply !text-paleskyblue 
} */

.additional_info {
  height: 108px !important;
}
.navigatebtn {
  width: 286px;
}

.react-datepicker {
  width: 300px;
  height: 300px;
  overflow: scroll;
  background-color: #131313;
  color: #edeff4;
  border: 2px solid white;
  position: absolute;
  z-index: 10;
}
.react-datepicker-ignore-onclickoutside {
  position: relative;
  top: 43px;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-evenly;
}
.react-datepicker__month {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  flex-direction: column;
}
.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
}
.react-datepicker__navigation--next {
  float: right;
}
.react-datepicker__aria-live {
  float: right;
}

.react-datepicker__input-container {
  margin-bottom: 10px;
}

.btntip {
  background: #edeff4;
  font-weight: 400;
  font-size: 12px;

  color: #080d23;

  border-radius: 43px;
  padding: 8px 16px 8px 16px;
}

.hrline {
  width: 250px;
  height: 1px;

  background: #edeff4;
  border-radius: 50px;
}
.steepers:last-child(14) {
  display: none;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 17px;
  background: #edeff4;
  border-radius: 42px;
  color: #474b64;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  z-index: 100;
  min-width: 40px;
  min-height: 40px;
}
.stepline {
  width: 83px;
  height: 2px;
  background: #edeff4;
}

.step::after {
  content: "";
  position: absolute;
  width: 83px;
  height: 2px;
  background: #edeff4;
  left: -83px;
}
.activestep::before {
  content: "";
  position: absolute;
  width: 88px;
  height: 2px;
  left: -90px;
  z-index: 100;

  @apply !bg-blacktype;
}

.hidden32::after,
.hidden32::before {
  display: none;
}

.pre_activestep::after {
  @apply !bg-blacktype;
}

.canbtn {
  font-weight: 500;
  font-size: 16px;
  color: #d63c2f !important;
  width: 640px;
  height: 55px;

  background: #fbebea !important;
  border-radius: 32px;
}

.homebtn {
  background: #edeff4 !important;
  border-radius: 32px;
  width: 158px;
  height: 47px;
  position: absolute;
  color: #080d23 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  left: 64px;
  top: 124px;
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}
.wave {
  width: 5px;
  height: 50px;
  background: linear-gradient(45deg, #080d23, #30cfe4);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.overlay {
  background-color: rgb(244 245 246);
  box-sizing: border-box;
  color: rgb(23 20 28);
  opacity: 0.4;
  width: 100%;
}
.react-timerange-picker__inputGroup__input {
  background-size: 0 1.5em !important;
}
